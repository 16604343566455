import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    constructor(private http: HttpClient) {}

    saveRegistration(data: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}api/registration/save`, data);
    }

    checkMail(email: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}check-mail`, { email });
    }

    passwordforgot(email: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}api/registration/reset-password`, { email });
    }

    passwordReset(password: string, token: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}api/registration/save-password`, { password, token });
    }

    getQuestions(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}api/registration/questions`);
    }

    getRandomPublicProducts(type:number): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}api/registration/public-products/${type}`);
    }
}
