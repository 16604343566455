import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { Product } from '../models/product';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    getUser(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}api/user`);
    }

    updateUser(user): Observable<User> {
        return this.http.patch<User>(`${environment.apiUrl}/api/user/personal-data`, user);
    }

    updatePassword(data: any): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}/api/user/change-password`, data);
    }

    getAllUser(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}api/admin/users`);
    }

    switchUser(id: number): Observable<any> {
        return this.http.get<any[]>(`${environment.apiUrl}api/admin/users/switch/${id}`);
    }

    updateLogo(user: number, file: any): Observable<User> {
        const formData = new FormData();
        if (file !== null) {
            formData.append('logo_file', file);
        }
        return this.http.post<User>(`${environment.apiUrl}/api/user/logo/${user}`, formData);
    }

    deleteLogo(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}api/user/logo/${id}/delete`);
    }

    deletePersonalImage(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}api/user/${id}/personal-image/delete`);
    }

    updatePersonalImage(id: number, file: any): Observable<any> {
        const formData = new FormData();
        if (file !== null) {
            formData.append('personal_image', file);
        }
        return this.http.post<any>(`${environment.apiUrl}/api/user/${id}/personal-image`, formData);
    }

    getAllSubAccounts(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}api/user/sub-accounts`);
    }

    getSubAccount(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}api/user/sub-accounts/${id}`);
    }

    addSubAccount(data: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/api/user/sub-account`, data);
    }

    updateSubAccount(data: any, id: number): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}/api/user/sub-account/${id}/update`, data);
    }

    deleteSubAccount(id: number): Observable<any> {
        return this.http.delete(`${environment.apiUrl}api/user/sub-account/${id}/delete`);
    }

    updateOwnSubAccount(data: any): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}/api/user/sub-account/own/update`, data);
    }
    // user/sub-account/own/update

    cofirmAGBStatus(): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}/api/user/update-agb-status`,{});
    }
}
