import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Dictionary } from '../models/dictionary';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class DictionaryService {
    constructor(private http: HttpClient) {}

    getCountries(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/country`);
    }


    async getItem(id: string): Promise<any> {
        return firstValueFrom(
            this.http.get<any>(`${environment.apiUrl}api/tools/dictionary/find/${id}`)
        );
    }

    getHotelBrands(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/brands`);
    }

    getLanguages(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/languages`);
    }

    getTourOperators(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/touroperators`);
    }

    getContactCategories(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/contactcategories`);
    }

    getCooperationChain(): Observable<Dictionary[]> {
        return this.http.get<Dictionary[]>(`${environment.apiUrl}api/tools/dictionary/cooperation_chain`);
    }

    async prepareCountries(_countries: any): Promise<any> {
        const data = [];
        const countries = await firstValueFrom(this.getCountries());
        if (_countries) {
            _countries.forEach((d: any) => {
                const item = _.find(countries, { id: d });
                data.push(item);
            });
            return data.map((e) => e.title).join(', ');
        }
    }
}
