import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Event } from '../models/event';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    constructor(private http: HttpClient) {}

    getNextEvents(): Observable<Event[]> {
        return this.http.get<Event[]>(`${environment.apiUrl}api/events/next`);
    }

    getMySubscriptions(): Observable<Event[]> {
        return this.http.get<Event[]>(`${environment.apiUrl}api/events/me`);
    }
}
